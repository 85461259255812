// Common Fields
export const APPLICATION_NAME = 'Application';
export const HELPER_TEXT_APPLICATION_NAME = 'Give a new name for the application';
export const APPLICATION_ABBREVIATION = 'App Friendly Name';
export const HELPER_TEXT_APPLICATION_ABBREVIATION = 'An Alpha-Numeric word (max 8 characters) that will be part of all cloud resources created for the app. Avoid using any special charcaters or Whitespaces.';
export const DESCRIPTION = 'Description';
export const HELPER_TEXT_DESCRIPTION = 'Provide description of your app (Max 256 characters)';
export const IOCODE = 'IO Code';
export const HELPER_TEXT_IOCODE = 'SAP IO Code is used in M&S to recharge for the infrastructure and resources created. Product Manager for your area will own and manage the IO Code.';
export const RECOVERY_TIME_OBJECTIVE = 'Recovery Time Objective (RTO)';
export const HELPER_TEXT_RECOVERY_TIME_OBJECTIVE = 'Indicate how long can business work whilst the app is unavailable';
export const DATA_CLASSIFICATION = 'Data Classsification';
export const HELPER_TEXT_DATA_CLASSIFICATION = 'What is the highest classification for data held at rest or in transit by the app. If in doubt, please contact InfoSec.';
export const LINK_TO_DOC = 'Link to documentation';
export const HELPER_TEXT_LINK_TO_DOC = 'Provide a link to the docs/confluence/sharepoint if you have one.';

export const CREATE_GITHUB_REPO = 'Create Github Repository';
export const OVERRIDE_GIT_REPO = 'Use Custom Github Repository Name';
export const HELPER_TEXT_OVERRIDE_GIT_REPO = 'The Github repository will be created with a custom name provided';
export const EXISTING_GIT_REPO = 'Existing Github Repository Name';
export const HELPER_TEXT_EXISTING_GIT_REPO = 'The Github repository should be present under DIgitalInnovation Org';
export const STACK = 'Select your stack for BrightCloud Infra automation';
export const SUPPORTED_BY = 'Supported By';
export const HELPER_TEXT_SUPPORTED_BY = 'Email ID. The mail should be in any one of the following format user@mnscorp.net|marks-and-spencer.com|mnscorp.onmicrosoft.com|mands.com.';
export const RESOURCE_OWNER = 'Resource Owner (Sr. Engineer/Architect)';
export const HELPER_TEXT_RESOURCE_OWNER = 'Provide the email id of Sr. Engineer/Architect to manage the resource admin activities. The mail should be in the format user@mnscorp.net.';
export const CREATE_AD_GROUP = 'Create AD group'
export const EXISTING_ADGROUP = 'Existing AD group details';
export const HELPER_TEXT_EXISTING_ADGROUP = 'Provide Existing AD Group details if not creating new AD group';
export const TEAM_MEMBER_INFO = 'User Email';
export const HELPER_TEXT_TEAM_MEMBER_INFO = 'Fill in user details with email id "@mnscorp.net" and mention the required access to each user.'
export const ENABLE_GITHUB_MAINTAINER = 'Enable GitHub Team RBAC';
export const GITHUB_MAINTAINER_NAME = 'Github ID for the Maintainer';
export const HELPER_TEXT_GITHUB_MAINTAINER_NAME = 'Configure access to the GitHub repo by adding a maintainer to your project and enable Github-AD sync. The id should be an existing account in the DigitalInnovation org.'
export const CREATE_YACCOUNT_TITLE = 'Creation of Service account or Providing Existing Service account details.'
export const ENABLE_CREATE_SERVICE_ACCOUNT  = 'Do you want to create a new Service Account (Y Account)?'
export const ENABLE_MAILBOX = 'Enable mail box for Service Account which is required to login to Github (Assigns an Office 365 F3 License which costs ~ £20 per year)';
export const EXISTING_YACCOUNT_ID = 'Existing Y account ID'
export const HELPER_TEXT_EXISTING_YACCOUNT_ID = 'Provide existing Y account ID in mnscorp.net format';
export const YACCOUNT_SECRET_KEYNAME = 'Y account Secret Key name';
export const HELPER_TEXT_YACCOUNT_SECRET_KEYNAME  = 'Secret Name of Existing Yaccount password stored in the github organization as secret. if you do not have organization secret created for your Y account password , please connect with cloud-devsecops team to get the secret created before raising this request.'


// CN
export const CREATE_PROD_NPROD_SUB = 'Create Staging and Prod Subscriptions';
export const CREATE_SANDBOX_sUBSCRIPTION = 'Create a sandbox subscription';



// V2
export const CREATE_RG = 'Create a Resource Groups';
export const CREATE_NONPROD_RG = 'Choose the nonprod environment';
export const HELPER_TEXT_NONPROD_RG = 'Select required Sub environment within Non-production.If you do not have any of the listed Sub environment you can select the option as None.This is used in resource naming convention and github environment to run the Github Action workflow jobs.';
export const CREATE_PROD_RG = 'Choose the prod environment';
export const HELPER_TEXT_PROD_RG = 'Select required Sub environment within Production.This is used in resource naming convention and github environment to run the Github Action workflow jobs.';
export const RUNNER_NAME = 'Team Name';
export const HELPER_TEXT_RUNNER_NAME = 'Your Team name under which Self hosted Runner is to be added - 10 characters or less - no hyphen, no underscore, should not start with number, no special characters';

