/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React from 'react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import { TextField, makeStyles } from '@material-ui/core';
import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';
import FormHelperText from '@material-ui/core/FormHelperText';
import { findValueByKey } from '../../../utils';

let uiSchemaOptions: any;
let GlobalValidation: string;
export const ResourceGroupCreationExtension = ({
  onChange,
  required,
  rawErrors,
  uiSchema,
  schema,
  formContext,
}: any) => {
  const discoveryApi = useApi(discoveryApiRef);
  const [responseToken, setResponseToken] = React.useState('');

  const [ResourceGroupExists, setResourceGroupExists] = React.useState(false!);
  const [resourceGroupChecked, setResourceGroupChecked] = React.useState(false);
  const [resourceGroupName, setResourceGroupName] = React.useState('');
  const [subscriptionId, setSubscriptionId] = React.useState('');

  // to fetch the ui:options from the schema
  uiSchemaOptions = uiSchema['ui:options'];
  const allowedForm = uiSchemaOptions?.form || [''];
  const allowedDelimiter = uiSchemaOptions?.delimiter
    ? uiSchemaOptions?.delimiter
    : ' ';
  const useDisable = uiSchemaOptions?.disable
    ? uiSchemaOptions?.disable
    : false;
  const dependentFields = uiSchemaOptions?.dependentFields
    ? uiSchemaOptions?.dependentFields
    : [];
  const useStyles = makeStyles({
    resourceGroupExists: {
      color: 'red',
    },
    resourceGroupNotExists: {
      color: 'green',
    },
  });

  // To Form the array of the form fields
  let FormArrayToConcate: string[] = allowedForm;
  allowedForm?.map((eachFormItem: string) => {
    if (eachFormItem?.includes('${{')) {
      const userindex = allowedForm?.indexOf(eachFormItem);
      const formField = eachFormItem
        .substring(3, eachFormItem.length - 2)
        .replace('parameters.', '')
        .trim();
      const fieldValue = findValueByKey(
        JSON.parse(JSON.stringify(formContext.formData)),
        formField.split('.')[formField.split('.').length - 1],
      );
      FormArrayToConcate[userindex] = fieldValue;
    }
  });

  // To check for any dependent fields
  let checkForDependentFields: string[] = dependentFields;
  dependentFields?.map((eachFormItem: string) => {
    if (eachFormItem?.includes('${{')) {
      const userindex = dependentFields?.indexOf(eachFormItem);
      const formField = eachFormItem
        .substring(3, eachFormItem.length - 2)
        .replace('parameters.', '')
        .trim();
      const fieldValue = findValueByKey(
        JSON.parse(JSON.stringify(formContext.formData)),
        formField.split('.')[formField.split('.').length - 1],
      );
      if (formField.split('.')[formField.split('.').length - 1] === 'id') {
        setSubscriptionId(fieldValue);
      }
      checkForDependentFields[userindex] = fieldValue;
    }
  });

  const customErrorMessage = uiSchemaOptions?.customErrorMessage
    ? uiSchemaOptions?.customErrorMessage
    : 'Resource Group Already Exists';
  const customMessage = uiSchemaOptions?.customMessage
    ? uiSchemaOptions?.customMessage
    : 'Resource Group Available';

  const FormArrayValue = allowedForm.join(allowedDelimiter);

  // To ensure the Validation is done
  React.useEffect(() => {
    setResourceGroupName(FormArrayValue);
    onChange(FormArrayValue);
    handleInputBlur();
  }, [FormArrayValue]);

  // To get the Access Token from the backend
  React.useEffect(() => {
    async function getAzureAccessTokenFromBackend() {

      const baseUrl = await discoveryApi.getBaseUrl('azureIntegration');
      await fetch(`${baseUrl}/getAzureAccessToken`, {
        method: 'GET',
        headers: {
          credentials: 'include',
        },
      })
        .then(response => response.json())
        .then(result => {
          setResponseToken(result);
          return result;
        })
        .catch(error => {
          console.log('error', error);
          return error;
        });
    }
    getAzureAccessTokenFromBackend();
  }, []);

  // To check for the Resource Group Existence
  async function checkforResourceGroupExistence(userDefinedResourceGroupName: string) {
    try {
      if (userDefinedResourceGroupName.trim() === '') {
        return false;
      }

      const url = `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups/${userDefinedResourceGroupName}?api-version=2020-06-01`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${responseToken}`,
          ConsistencyLevel: 'eventual',
        },
        redirect: 'follow',
      });

      const errorResponseCodes = [400, 401, 403, 404];
      const noContentResponseCodes = [200, 201, 204];

      const checkErrorCodes = errorResponseCodes?.includes(response?.status);
      const ResponseCodes = noContentResponseCodes?.includes(response?.status);
      console.log('REsponse', response.status);

      if (checkErrorCodes) {
        GlobalValidation = 'doNotExists';
        return false;
      }
      if (ResponseCodes) {
        GlobalValidation = 'exists';
        return true;
      }
    } catch (error) {
      console.error('Resource Group Existence Error', error);
      return false;
    }
    return false;
  }

  // Validation based on each key press
  const handleInputChange = React.useCallback(
    async (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      if (value.trim() === '') {
        setResourceGroupName('');
        setResourceGroupChecked(false);
        GlobalValidation = 'empty';
        onChange('');
        return;
      }

      setResourceGroupName(value);
      setResourceGroupChecked(false);

      onChange(value);

      if (subscriptionId) {
        try {
          const exists = await checkforResourceGroupExistence(value);
          setResourceGroupExists(exists || false);
          setResourceGroupChecked(true);
        } catch (error) {
          console.error('Not able to validate Resource Groups');
        }
      } else {
        GlobalValidation = 'subscriptionIdEmpty';
      }
    },
    [subscriptionId],
  );

  // Validation based on blur i.e. when the user clicks outside the input field
  const handleInputBlur = async () => {
    if (resourceGroupName.trim() === '') {
      setResourceGroupChecked(false);
      return;
    }
    try {
      const exists = await checkforResourceGroupExistence(resourceGroupName);
      setResourceGroupExists(exists || false);
      setResourceGroupChecked(true);
    } catch (error) {
      console.error('Not able to validate Resource Group');
    }
  };

  let widthRG = '100%';
  const classes = useStyles();

  // Helper text for existing group name
  const existingGroupHelperText = ResourceGroupExists ? customErrorMessage : '';

  // Helper text for available group name
  const availableGroupHelperText =
    resourceGroupChecked && !ResourceGroupExists ? customMessage : '';

  return (
    <>
      <FormControl
        margin="normal"
        style={{ flexDirection: 'row' }}
        required={required}
        error={rawErrors?.length > 0}
      >
        <TextField
          value={resourceGroupName}
          label={schema.title}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(event, event.target.value)
          }
          onBlur={handleInputBlur}
          className={
            resourceGroupName
              ? classes.resourceGroupExists
              : classes.resourceGroupNotExists
          }
          style={{ width: widthRG }}
          variant="outlined"
          error={ResourceGroupExists ? true : false}
          color="primary"
          helperText={
            ResourceGroupExists
              ? ResourceGroupExists && (
                  <div style={{ color: 'red' }}>{existingGroupHelperText}</div>
                )
              : resourceGroupChecked &&
                !ResourceGroupExists && (
                  <div style={{ color: 'green' }}>
                    {availableGroupHelperText}
                  </div>
                )
          }
          disabled={useDisable ? true : false}
        />
        {schema.description && (
          <FormHelperText>{schema.description}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

// Exclusive method to Validate the Resource Group Name
export const RGvalidation = (value: string, validation: FieldValidation) => {
  const regexExpression = uiSchemaOptions?.regexExp;
  if (regexExpression) {
    const regex = new RegExp(regexExpression);
    const isValid = regex.test(value);
    if (
      !isValid &&
      GlobalValidation !== 'empty' &&
      GlobalValidation !== 'exists'
    ) {
      GlobalValidation = 'Regex mis-match';
    }
  }

  if (uiSchemaOptions?.validate) {
    if (
      GlobalValidation === 'exists' &&
      uiSchemaOptions.validate &&
      uiSchemaOptions.form
    ) {
      validation.addError('');
    } else if (
      GlobalValidation === 'empty' &&
      uiSchemaOptions.validate &&
      uiSchemaOptions.form
    ) {
      validation.addError('This is a mandatory Field');
    } else if (
      GlobalValidation === 'Regex mis-match' &&
      uiSchemaOptions.validate &&
      uiSchemaOptions.regexExp
    ) {
      validation.addError(
        'The expression is not matching with the Regex Expression',
      );
    } else if (GlobalValidation === 'exists') {
      validation.addError('');
    } else if (GlobalValidation === 'empty') {
      validation.addError('This is a mandatory Field');
    }
    if (GlobalValidation === 'subscriptionIdEmpty') {
      validation.addError('Please select a subscription Id');
    }
  } else {
    if (GlobalValidation === 'exists' && uiSchemaOptions.form) {
      validation.addError('');
    } else if (GlobalValidation === 'empty' && uiSchemaOptions.form) {
      validation.addError('This is a mandatory Field');
    } else if (
      GlobalValidation === 'Regex mis-match' &&
      uiSchemaOptions.regexExp
    ) {
      validation.addError(
        'The expression is not matching with the Regex Expression',
      );
    } else if (GlobalValidation === 'exists') {
      validation.addError('');
    } else if (GlobalValidation === 'empty') {
      validation.addError('This is a mandatory Field');
    }
    if (GlobalValidation === 'subscriptionIdEmpty') {
      validation.addError('Please select a subscription Id');
    }
  }
};
