import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField, { TextFieldVariants } from '@mui/material/TextField';
import { appThemeApiRef, discoveryApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import logger from './logger';

// Define the props for the component
interface GithubRepositoryProps {
  handleGithubRepositoryChange: (newValue: any) => void;
  setBorderColor: string;
  setOnHoverColor: string;
  variant: TextFieldVariants;
}

// Define the structure of a repository
interface Repository {
  github_repository_name: string;
  github_repository_url: string;
}

// Define the RepositoryNamePicker component
export const RepositoryNamePicker: React.FC<GithubRepositoryProps> = ({
  handleGithubRepositoryChange,
  setBorderColor,
  setOnHoverColor,
  variant,
}) => {
  // Access the required APIs
  const theme = useApi(appThemeApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);

  // Define state variables
  const [repositories, setRepositories] = useState<Repository[]>([]);
  const [userInputTxt, setUserInputTxt] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch repositories based on user input
  useEffect(() => {
    const fetchRepositories = async () => {
      try {
        setLoading(true);
        const baseUrl = await discoveryApi.getBaseUrl('github');
        const response = await fetchApi.fetch(`${baseUrl}/search/repository?userInputTxt=${encodeURIComponent(userInputTxt)}`, {
          method: 'GET',
          headers: {
            'credentials': 'include',
            'Content-Type': 'application/json',
            'accept': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const repositoryNames = await response.json();
        setRepositories(repositoryNames);
        setLoading(false);
      } catch (error) {
        logger.error(`Error fetching data:${error}`);
      }
    };

    fetchRepositories();
  }, [discoveryApi, userInputTxt, fetchApi]);

  // Render the Autocomplete component
  return (
    <Autocomplete

      clearIcon={<CloseIcon fontSize="small" style={{ color: theme.getActiveThemeId() === "light" ? "black" : "white" }} />}
      popupIcon={<ArrowDropDownIcon fontSize="small" style={{ color: theme.getActiveThemeId() === "light" ? "black" : "white" }} />}
      loading={loading} // Use the loading state to control the loading indicator
      options={repositories}
      getOptionLabel={(option: any) => option.github_repository_name}
      onChange={(_, value) => handleGithubRepositoryChange(value || '')}
      loadingText={<span style={{ color: theme.getActiveThemeId() === "light" ? "black" : "white" }}>Loading ....</span>}
      noOptionsText={<span style={{ color: theme.getActiveThemeId() === "light" ? "black" : "white" }}> No Options</span>}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder='Github Repository'
          variant={variant}
          size="small"
          required
          onChange={(e) => {
            setUserInputTxt(e.target.value);
          }}
          sx={{
            '& fieldset': {
              borderRadius: '8px',
              borderColor: setBorderColor,
            },
            '&:hover fieldset': {
              borderColor: `${setOnHoverColor}!important`,
            },
            input: {
              color: theme.getActiveThemeId() === "light" ? "#000000  !important" : "#ffffff  !important",
              '&::placeholder': {
                color: theme.getActiveThemeId() === "light" ? "#000000  !important" : "#ffffff  !important",
              },
            },
          }}
        />
      )
      }
    />
  );
};
