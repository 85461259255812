/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Tooltip,
  Chip,
  makeStyles,
  IconButton,
  Typography,
  DialogActions,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useApi, discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';
import { fetchGithubRepositoryExistence } from '../api';
import {
  OVERRIDE_GIT_REPO,
  HELPER_TEXT_OVERRIDE_GIT_REPO,
  EXISTING_GIT_REPO,
} from '../../../../constants/ecosystemBuilderCreationConstants';

const useStyles = makeStyles({
  confirmButton: {
    float: 'right',
  },
});

interface GithubRepositoryFormProps {
  open: boolean;
  onClose: () => void;
  formdata: boolean;
  githubRepositoryCreateChecked: boolean;
  githubRepoName: string;
  existingGithubRepoName: string;
  handleOverrideGithubRepoName: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  handleExistingGithubRepoName: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  GithubRepositoryError: string;
  handleGlobalError2: (error: string) => void;
  setOpenGithubDialog: (isOpen: boolean) => void;
  setGithubRepositoryCreateChecked: (isChecked: boolean) => void;
}

const GithubRepositoryForm: React.FC<GithubRepositoryFormProps> = ({
  open,
  onClose,
  formdata,
  githubRepositoryCreateChecked,
  githubRepoName,
  existingGithubRepoName,
  handleGlobalError2,
  GithubRepositoryError,
  handleOverrideGithubRepoName,
  handleExistingGithubRepoName,
  setOpenGithubDialog,
  setGithubRepositoryCreateChecked,
}) => {
  const [available, setAvailable] = useState(false);
  const [existing, setExisting] = useState(false);
  const [errorText, setErrorText] = useState<string>('');
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const classes = useStyles();

  useEffect(() => {
    const fetchGithubRepoData = async () => {
      if (formdata && githubRepoName) {
        try {
          const githubRepoStatus = await fetchGithubRepositoryExistence(
            githubRepoName,
            discoveryApi,
            fetchApi,
          );
          if (githubRepoStatus.exists) {
            setExisting(true);
            handleGlobalError2('exists');
            GithubRepositoryError === 'exists';
            setErrorText('');
            setAvailable(false);
          } else if (!githubRepoName) {
            setExisting(false);
            setErrorText('Repository cannot be empty.');
            setAvailable(false);
            handleGlobalError2('doNotExists');
          } else {
            setAvailable(true);
            setExisting(false);
            handleGlobalError2('doNotExists');
            GithubRepositoryError === 'doNotExists';
            setErrorText('Invalid Repository. Please enter a valid one.');
          }
        } catch (error) {
          console.error('Error in fetching repository! Try again later or contact Muziris Support for further help,', error);
        }
      }
    };
    fetchGithubRepoData();
  }, [formdata, githubRepoName]);

  const handleDialogClose = () => {
    if (githubRepoName && githubRepoName.trim() !== '' && existing) {
      setOpenGithubDialog(false);
    } else {
      setGithubRepositoryCreateChecked(false); // Reset checkbox state if dialog is closed without a value
    }
  };

  return (
    <>
      {githubRepositoryCreateChecked ? (
        <TextField
          label={OVERRIDE_GIT_REPO}
          value={githubRepoName}
          onChange={handleOverrideGithubRepoName}
          fullWidth
          margin="dense"
          variant="outlined"
          error={existing && !available}
          helperText={
            available
              ? HELPER_TEXT_OVERRIDE_GIT_REPO
              : existing
                ? 'Repository Name already exists'
                : HELPER_TEXT_OVERRIDE_GIT_REPO
          }
        />
      ) : (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
          <DialogTitle>Repository Name</DialogTitle>
          <DialogContent style={{ height: '110px' }}>
            <TextField
              label={EXISTING_GIT_REPO}
              value={githubRepoName}
              onChange={handleExistingGithubRepoName}
              fullWidth
              margin="dense"
              variant="outlined"
              error={!existing}
              helperText={
                existing || 'Repository does not exist in DigitalInnovation'
              }
            />
          </DialogContent>
          <DialogActions>
            <IconButton
              className={classes.confirmButton}
              color="primary"
              onClick={handleDialogClose}
              disabled={!existing}
            >
              <AddIcon />
            </IconButton>
            {errorText && (
              <Typography variant="caption" color="error">
                {errorText}
              </Typography>
            )}
          </DialogActions>
        </Dialog>
      )}
      <div>
        {!githubRepositoryCreateChecked &&
          formdata &&
          existingGithubRepoName.length >= 0 && (
            <>
              <Tooltip title="Existing Github Repository">
                <Chip
                  label={`Click Here To Add Existing Github Repository Title: ${githubRepoName}`}
                  color="primary"
                  variant="default"
                  onClick={() => {
                    setGithubRepositoryCreateChecked(false);
                    setOpenGithubDialog(true);
                  }}
                />
              </Tooltip>
            </>
          )}
      </div>
    </>
  );
};

export default GithubRepositoryForm;
