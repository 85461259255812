/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { DiscoveryApi, useApi, microsoftAuthApiRef, FetchApi } from "@backstage/core-plugin-api";
import { useAsync } from "react-use";

export async function fetchMal(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    try {
        const baseUrl = await discoveryApi.getBaseUrl('msgraph');
        const response = await fetchApi.fetch(`${baseUrl}/v2/sharepoint/MAL/listAppsInfo`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                }
            });
        if (!response.ok) {
            throw new Error(`Failed to fetch contents, ${response.status} ${response.statusText}`);
        }
        const data = await response.json();

        return data;


    }
    catch (error: any) {
        console.error(`Error: ${error.message}`);
    }
}

export async function fetchPdt(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    try {
        const baseUrl = await discoveryApi.getBaseUrl('msgraph');
        const response = await fetchApi.fetch(`${baseUrl}/v2/sharepoint/D&TProduct&TeamCatalogue/listProducts&TeamInfo`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                }
            });
        if (!response.ok) {
            throw new Error(`Failed to fetch contents, ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        const filteredValue = data.filter((item: any) => item.productName !== "TBC");

        return filteredValue;


    }
    catch (error: any) {
        console.error(`Error: ${error.message}`);
    }
}


export async function fetchAlias(aliasValue: string, discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    try {
        const baseUrl = await discoveryApi.getBaseUrl('msgraph');
        const query = `filter=fields%2FAlias+eq+%27${aliasValue}%27`;
        const response = await fetchApi.fetch(`${baseUrl}/v2/sharepoint/MAL/filteredMal?${query}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error(
                `Failed to fetch contents, ${response.status} ${response.statusText}`,
            );
        }
        const data = await response.json(); // this is returning a promise

        const aliasChecked = data.items[0].alias;
        return aliasChecked;
    }
    catch (error: any) {
        console.error(`Error: ${error.message}`);
    }
}

export async function fetchGithubRepositoryExistence(repoName: string,discoveryApi: DiscoveryApi, fetchApi: FetchApi): Promise<{ exists: boolean, data?: any, message?: string }> {
    try {
        const baseUrl = await discoveryApi.getBaseUrl('github');
        const url =`${baseUrl}/org/repos/${repoName}`
        const response = await fetchApi.fetch(url, {
            method: 'GET',
            headers: {
                'credentials': 'include',
                Accept: 'application/vnd.github.v3+json',
                "X-GitHub-Api-Version": '2022-11-28'
            }
        });

        if (response.ok) {
            const repositoryData = await response.json();
            return { exists: true, data: repositoryData, message: "Repository already exists" };
        } else if (response.status === 404) {
            return { exists: false, message: "Repository not found" };
        } else {
            throw new Error(`Failed to fetch repository, ${response.status} ${response.statusText}`);
        }
    } catch (error: any) {
        return { exists: false, message: error.message };
    }
}


export async function fetchGithubUserName(githubUserName: string,discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    const baseUrl = await discoveryApi.getBaseUrl('github');
    const apiUrl =`${baseUrl}/org/users/${githubUserName}`

    try {
        const response = await fetchApi.fetch(apiUrl, {
            headers: {
                'credentials': 'include',
                "X-GitHub-Api-Version": "2022-11-28",
                Accept: "application/vnd.github.v3+json",
            },
        });

        return response; // Return the entire response
    } catch (error) {
        console.error("Error fetching GitHub user information:", error);
        throw error; // Throw the error for the calling function to handle
    }
}


export function microsoftAzureToken() {
    const apiRef = useApi(microsoftAuthApiRef);
    const { value: token } = useAsync(async () => { return await apiRef.getAccessToken(); });
    return token;
}

export async function fetchAzureADGroups(adGroupName: any, token: any): Promise<any> {
    const graphApiUrl = `https://graph.microsoft.com/v1.0/groups?$search=%22displayName%3a${adGroupName}%22&$select=id,displayName`;
    const graphApiResponse = await fetch(graphApiUrl, {
        headers: {
            Authorization: `Bearer ${token}`,
            consistencyLevel: 'eventual',
        },
    });

    return graphApiResponse.json();
}


export async function fetchAzureADUsers(email: any, token: any): Promise<any> {
    try {
        const apiUrl = `https://graph.microsoft.com/v1.0/users?$filter=(userPrincipalName eq '${email}' or mail eq '${email}') and accountEnabled eq true`;
        const response = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
                consistencyLevel: 'eventual',
            },
        });

        if (response.ok) {
            const data = await response.json();
            return data.value.length > 0;
        } else {
            console.error('Error checking user:', response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error checking user:', error);
        return false;
    }
};

export const fetchAzureGroupEmail = async (groupId: string, token: any) => {   
    try {
        const response = await fetch(
            `https://graph.microsoft.com/v1.0/groups?$filter=mail eq 	%27${groupId}%27`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    ConsistencyLevel: 'eventual',
                },
            },
        );
        if (response.ok) {
            const data = await response.json();
            return data.value.length > 0;
        } else {
            console.error('Error checking user:', response.statusText);
            return false;
        }
    }
    catch (error) {
        console.error('Error checking user:', error);
        return false;
    }
}

export const validateEmail = (value: any, _checkRegex: any) => {
    const regex = new RegExp(_checkRegex);
    return regex.test(value);
};