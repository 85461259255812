/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-else-return */
/* eslint-disable no-console */
import { useApi, discoveryApiRef, fetchApiRef } from "@backstage/core-plugin-api";
import { useEffect, useState } from "react";
import { fetchAlias, validateEmail } from '../api';
import { FormHelperText, Grid, TextField } from "@material-ui/core";
import React from "react";
import CircularProgressWithLabel from "../../EcosystemBuilderUpdate/circularProgressWithLabel";
import { APPLICATION_ABBREVIATION, HELPER_TEXT_APPLICATION_ABBREVIATION } from '../../../../constants/ecosystemBuilderCreationConstants'


interface AliasFieldProps {
    aliasName: string;
    handleAliasChange: (_: any) => void;
    handleAliasNameError: (_: any) => void;
    AliasNameError: string;
}

const AliasField: React.FC<AliasFieldProps> = ({
    aliasName,
    // AliasNameError,
    handleAliasChange,
    handleAliasNameError,

}) => {
    // const [token, setToken] = useState('');
    const discoveryApi = useApi(discoveryApiRef);
    const fetchApi = useApi(fetchApiRef);
    const [showError, setShowError] = React.useState(false);
    const [pollingInterval, setPollingInterval] = useState<any>(null);
    const [regexError, setRegexError] = React.useState(false);
    const [regexErrorText, setRegexErrorText] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if(!loading){
            setPollingInterval(null);
        } 
    }, [loading]);

    const fetchAliasInfo = async () => {
        try {
            setLoading(true);
            const startTime = performance.now();
            let elapsedTime = 0;

            // Function to update the elapsed time and trigger a re-render
            const updateElapsedTime = () => {
                const currentTime = performance.now();
                elapsedTime = ((currentTime - startTime) / 1000);
                setPollingInterval(elapsedTime); // Pass elapsed time to setLoading
            };

            // Update elapsed time every 100 milliseconds
            const timerId = setInterval(updateElapsedTime, 100);

            const checkAliasInfo = await fetchAlias(aliasName, discoveryApi,fetchApi);
            if (checkAliasInfo) {
                setShowError(true);
                handleAliasNameError('exists');
                // AliasNameError="exists";
            }
            else {
                setShowError(false);
                setRegexError(false);
                handleAliasNameError('doNotExists');
                // AliasNameError="doNotExists";
                setRegexErrorText('');
                setLoading(false);
                setPollingInterval(null);
            }

            await new Promise((resolve) => setTimeout(resolve, 3000));

            const endTime = performance.now();
            elapsedTime = ((endTime - startTime) / 1000);
            setPollingInterval(elapsedTime);

            clearInterval(timerId); // Stop the timer

            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error('Error fetching GitHub token:', error);
        }
    };

    useEffect(() => {
        setShowError(false);
        setRegexError(false);
        setRegexErrorText('');
        setLoading(true);

        const validate = async () => {
            if (aliasName.trim() === '') {
                // Handle validation or show an alert
                return;
            }
            const regex = "/^[a-zA-Z0-9]{3,10}$/";
            const isValidAlias = validateEmail(aliasName, regex);

            if (!isValidAlias) {
                setRegexError(true);
                setRegexErrorText('Invalid Alias. Please enter a valid one.');
                handleAliasNameError('doNotExists');
                // AliasNameError="doNotExists";
                return;
            }
            else {
                setRegexError(false);
                handleAliasNameError('exists');
                // AliasNameError="exists";
                setRegexErrorText('');
            }

        }

        validate();
        fetchAliasInfo();

    },
        [aliasName]
    );

    return (
        <>

            <TextField
                id="alias"
                label={APPLICATION_ABBREVIATION}
                helperText={showError ? "Alias already exists" : HELPER_TEXT_APPLICATION_ABBREVIATION}
                value={aliasName}
                onChange={handleAliasChange}
                error={showError}
                fullWidth
                variant="outlined"
                margin='dense'
                required
            />
            {loading && (
                <Grid item>
                    <CircularProgressWithLabel value={pollingInterval} />
                </Grid>
            )}
            {regexErrorText && (<FormHelperText error={regexError}>{regexErrorText}</FormHelperText>)}
        </>
    );
};

export default AliasField;